<template>
  <div class="questions__bar mx-4 px-4">
    <b-row class="align-items-center justify-content-center">
      <b-col
        v-for="question in questions"
        :key="question.id"
        cols="4"
        md="2"
        class="py-3"
        @click="selectQuestion(question.id)"
      >
        <img
          :src="questionNo > question.id ? openLock : closedLock"
          :class="questionNo > question.id ? 'cursor-pointer' : ''"
        />
        <p :class="questionNo > question.id ? 'cursor-pointer' : ''">
          {{ question.label }}
        </p>
      </b-col>
      <b-col cols="4" md="2" class="py-3">
        <img :src="treasureClosed" />
        <p>Aanbieding</p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import closedLock from "@/assets/closed-lock.png";
import treasureClosed from "@/assets/treasure-closed.png";
import openLock from "@/assets/open-lock.png";

export default {
  name: "LockedQuestionBar",
  props: ["questionNo"],
  data() {
    return {
      closedLock,
      treasureClosed,
      openLock,
      questions: [
        { id: 1, label: "Adres check" },
        { id: 2, label: "Zonnepanlelen" },
        { id: 3, label: "Huidig contract" },
        { id: 4, label: "Type woning" },
        { id: 5, label: "Gezin" },
      ],
    };
  },
  methods: {
    selectQuestion(selectedQuestion) {
      if (selectedQuestion < this.questionNo) {
        this.$emit("update-question", selectedQuestion);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.questions__bar {
  background-color: #fca12a;
  color: white;
  border-radius: 10px;
}
p {
  margin: 0;
  margin-top: 10px;
  font-size: 10px;
  font-weight: 600;
}
img {
  max-width: 100%;
}
</style>
