import LeadRepository from "./LeadRepository";
import AddressRepository from "./AddressRepository";

const repositories = {
  leads: LeadRepository,
  address: AddressRepository,
};

export const RepositoryFactory = {
  get: (name) => repositories[name],
};
