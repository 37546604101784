<template>
  <div class="text-center">
    <b-container class="py-4 text-start">
      <b-row>
        <b-col sm="12" md="4" lg="3" class="text-center">
          <img :src="subHeader" />
        </b-col>
        <b-col
          sm="12"
          md="7"
          lg="7"
          offset-md="1"
          class="d-flex flex-column justify-content-center pt-5 pt-md-0"
        >
          <h5 class="fw--700">BesparenKan is eerlijk en objectief:</h5>
          <h5 class="fw--400">
            Op basis van jouw persoonlijke verbruik wordt er een berekening
            gemaakt. BesparenKan geeft je een eerlijk en objectief advies. De
            daadwerkelijke energieleverancier kies je uiteraard zelf.
          </h5>
          <br />
          <h5 class="fw--700">
            BesparenKan regelt de gehele overstap voor je:
          </h5>
          <h5 class="fw--400">
            Dit maakt het overstappen gemakkelijk en zonder zorgen.
          </h5>
        </b-col>
      </b-row>
    </b-container>
    <img :src="divider" class="mx-auto" style="width: 70%" />
  </div>
</template>
<script>
import subHeader from "@/assets/sub-header.png";
import divider from "@/assets/divider.png";
export default {
  name: "SubHeader",
  data() {
    return {
      subHeader,
      divider,
    };
  },
};
</script>
<style lang="scss" scoped>
img {
  max-width: 90%;
}
p {
  margin: 0;
}
</style>
