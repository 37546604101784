import { RepositoryFactory } from "../../repository/RepositoryFactory";

let address = RepositoryFactory.get("address");
const state = {
  addressLoader: false,
  fullAddress: null,
};

const getters = {
  getAddressLoader: (state) => state.addressLoader,
  getFullAddress: (state) => state.fullAddress,
};

const actions = {
  async getAddress({ commit }, payload) {
    commit("setAddressLoader", true);
    try {
      const response = await address.getAddress(payload);
      commit("setAddressLoader", false);
      const resp = response.data[0];
      commit("setFullAddress", resp);
      return {
        addressExists: resp,
      };
    } catch (error) {
      console.log(error.response);
      commit("setAddressLoader", false);
    }
  },
};

const mutations = {
  setAddressLoader(state, payload) {
    state.addressLoader = payload;
  },
  setFullAddress(state, payload) {
    state.fullAddress = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
