import axios from "axios";
import qs from "qs";

const baseDomain = "https://leadgen.deambassadeur.nu/api/sponsors/2254";
const baseURL = `${baseDomain}/`;
let axiosObj;
axiosObj = axios.create({
  baseURL,
  headers: {
    Authorization: `Basic MTg1OmFiODIyMWQ0YTMxNzBkODk1NDI4ODA0NTlhYmY3OTgxN2FlMzY3YzI=`,
  },
});

const LEADS = "leads";

export default {
  postLead(payload) {
    return axiosObj.post(`${LEADS}`, qs.stringify(payload));
  },
};
