<template>
  <div class="header text-center">
    <b-container>
      <div v-if="!isFinalForm">
        <h2 class="header__title">
          Het is mogelijk om nu tot wel honderden euro's per jaar te besparen op
          je energierekening
        </h2>
        <h2 class="header__subtitle">
          Ontgrendel jouw persoonlijke, exclusieve aanbieding.
        </h2>
      </div>
      <div class="header__card">
        <FormCardMain />
      </div>
    </b-container>
  </div>
</template>

<script>
import FormCardMain from "../form-card/FormCardMain.vue";
export default {
  name: "HeaderMain",
  components: { FormCardMain },
};
</script>

<style scoped lang="scss">
.header {
  padding: 4rem 0;
  background: url(../../assets/header-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: -25px;
  &__title {
    color: #ffffff;
    max-width: 70%;
    margin: auto;
    @media (max-width: 991.98px) {
      max-width: 100%;
    }
  }
  &__subtitle {
    color: #ffffff;
    font-weight: 700;
    margin: auto;
    padding-top: 1rem;
  }
  &__card {
    background-color: #ffffff;
    max-width: 60%;
    margin: auto;
    margin-top: 3rem;
    padding: 3rem 0;
    border-radius: 15px;
    box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.8);
    -webkit-box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.8);
    -moz-box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.8);

    @media (max-width: 991.98px) {
      max-width: 95%;
    }
  }
}
</style>
