<template>
  <div class="bedankt">
    <h2 class="fw--700">Bedankt voor uw aanvraag!</h2>
    <img
      v-if="getProfileId"
      referrerpolicy="no-referrer-when-downgrade"
      :src="`https://republish.besparingexpert.nl/m/5888/2ab524831fad/?event=6863&unique_conversion_id=${getProfileId}`"
      style="width: 1px; height: 1px; border: 0px"
    />
  </div>
</template>

<script>
export default {
  name: "Bedankt",
};
</script>

<style scoped lang="scss">
.bedankt {
  height: 100%;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
