<template>
  <b-container class="text-start px-4">
    <b-row>
      <b-col sm="12" md="4" class="text-center"
        ><img :src="headerImg" class="d-none d-md-block" /><img
          :src="headerImgSm"
          class="d-block d-md-none m-auto"
      /></b-col>
      <b-col sm="12" md="8" class="d-flex align-items-center"
        ><p>
          BesparenKan heeft exclusieve deals met verschillende
          energieleveranciers weten te sluiten, hierdoor kun jij gebruik maken
          van aantrekkelijke aanbiedingen.
        </p></b-col
      >
    </b-row>
    <b-row class="py-4">
      <b-col cols="3" md="1" class="d-flex align-items-center"
        ><img :src="nextIcon" class="ml-auto"
      /></b-col>
      <b-col cols="9" md="11"
        ><h5 class="fw--700">
          Geef antwoord op de vragen, ontgrendel de sloten en ontdek jouw
          persoonlijke en exclusieve aanbieding.
        </h5></b-col
      >
    </b-row>
  </b-container>
</template>
<script>
import headerImg from "@/assets/form-card-header.png";
import headerImgSm from "@/assets/form-card-header-sm.png";
import nextIcon from "@/assets/form-card-next-icon.png";

export default {
  name: "CardHeader",
  data() {
    return {
      headerImg,
      headerImgSm,
      nextIcon,
    };
  },
};
</script>
<style lang="scss" scoped>
p {
  margin: 0;
}
img {
  max-width: 90%;
}
</style>
