<template>
  <b-container class="finalForm text-center px-2 px-md-4">
    <img :src="unlocked" width="100" style="max-width: 90%" />
    <h3 class="fw--700 mb-0 mt-2">Gefeliciteerd!</h3>
    <h5 class="fw--700 m-0">
      Jij kunt mogelijk honderden euro's besparen op je energiekosten.
    </h5>
    <img :src="dividerImg" style="max-width: 80%" />
    <h4>Ontvang nu gratis jouw persoonlijke en exclusieve aanbieding:</h4>
    <!-- input fields -->
    <b-form @submit.prevent="onSubmit" class="finalForm--form mt-3">
      <b-row class="my-3">
        <b-col>
          <b-form-input
            id="firstname"
            placeholder="Voornaam"
            v-model="formData.firstname"
            :state="validateField(formData.firstname, nameRegex)"
            required
          ></b-form-input>
        </b-col>
        <b-col>
          <b-form-input
            id="lastname"
            placeholder="Achternaam"
            v-model="formData.lastname"
            :state="validateField(formData.lastname, nameRegex)"
            required
          ></b-form-input>
        </b-col>
      </b-row>
      <b-row class="my-3">
        <b-col>
          <b-form-input
            id="phone_number"
            type="tel"
            placeholder="Telefoonnummer"
            v-model="formData.phone_number"
            :state="validateField(formData.phone_number, phoneRegex)"
            maxlength="10"
            required
          ></b-form-input>
        </b-col>
        <b-col>
          <b-form-input
            id="email"
            placeholder="E-mail adres"
            v-model="formData.email"
            :state="validateField(formData.email, emailRegex)"
            required
          ></b-form-input>
        </b-col>
      </b-row>
      <b-button
        type="submit"
        pill
        variant="success"
        class="my-3 px-4 submit-btn"
        :disabled="!isValidForm"
      >
        <b-spinner small v-if="getSubmitLoader" class="text-center mx-5 my-1" />
        <span v-else>
          BEVESTIGEN &nbsp;&nbsp;&nbsp;&nbsp;<img :src="arrowIcon" />
        </span>
      </b-button>
    </b-form>
    <p class="mt-4 beneath_form">
      <span class="fw--700">Wat gebeurt er met je gegevens?</span> Door op
      bevestigen te klikken worden je gegevens gebruikt om te berekenen hoeveel
      je kunt besparen op je energiekosten maximaal vier keer per jaar. De
      berekening wordt gratis en vrijblijvend telefonisch gedaan door een
      gecertificeerde energieadviseur van Besparenkan. Wil je dit toch niet
      meer, dan kun je je toestemming intrekken via
      <a
        href="https://besparenkan.nl/afmelden"
        target="_blank"
        class="underForm--link"
        >het formulier</a
      >
      op onze website.
    </p>
  </b-container>
</template>

<script>
import unlocked from "@/assets/end-form.png";
import dividerImg from "@/assets/divider.png";
import arrowIcon from "@/assets/btn-arrow.png";

export default {
  name: "FinalForm",
  props: ["flowAnswers"],

  data() {
    return {
      unlocked,
      arrowIcon,
      dividerImg,
      formData: {
        language: "nl_NL",
        publisher_id: "",
        site_subid: "",
        site_custom_url: " https://besparenkan.besparingexpert.nl",
        site_custom_name: "BesparenKan_schatkist",

        firstname: "",
        lastname: "",
        phone_number: "",
        email: "",
      },
    };
  },
  methods: {
    validateField(value, regex) {
      if (value === "") {
        return null;
      }
      return regex.test(value);
    },
    async onSubmit() {
      if (this.isValidForm) {
        await this.postLead({ ...this.flowAnswers, ...this.formData });
      }
    },
  },
  created() {
    (this.formData.site_subid = this.$route.query.click),
      (this.formData.publisher_id = this.$route.query.site);
    this.noscriptHtml =
      '<noscript><img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=802121461641597&ev=PageView&noscript=1" /></noscript>';
  },
  computed: {
    nameRegex() {
      return /^([a-zA-Z ]){1,30}$/;
    },
    emailRegex() {
      return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    },
    phoneRegex() {
      return /^([0])(\d{9})$/;
    },
    isValidForm() {
      return (
        this.validateField(this.formData.firstname, this.nameRegex) &&
        this.validateField(this.formData.lastname, this.nameRegex) &&
        this.validateField(this.formData.email, this.emailRegex) &&
        this.validateField(this.formData.phone_number, this.phoneRegex)
      );
    },
  },
  mounted() {
    let useScript = document.createElement("script");
    useScript.setAttribute(
      "src",
      "https://connect.facebook.net/en_US/fbevents.js"
    );
    useScript.setAttribute("async", true);

    window.fbq = function () {
      if (window.fbq.callMethod) {
        window.fbq.callMethod.apply(window.fbq, arguments);
      } else {
        window.fbq.queue.push(arguments);
      }
    };
    if (!window._fbq) {
      window._fbq = window.fbq;
    }

    window.fbq.push = window.fbq;
    window.fbq.loaded = true;
    window.fbq.version = "2.0";
    window.fbq.queue = [];
    fbq("init", "802121461641597");

    fbq("track", "PageView");
    fbq("track", "Lead");
    document.head.appendChild(useScript);
  },
};
</script>

<style scoped lang="scss">
.finalForm--form {
  max-width: 70%;
  margin: auto;
  @media (max-width: 991.98px) {
    max-width: 100%;
  }
}
.underForm--link {
  color: #666666;
  text-decoration: none;
  cursor: pointer;
  font-weight: 500;
}
.beneath_form {
  color: #666666;
  font-size: 13px;
}
</style>
