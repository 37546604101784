<template>
  <div class="unlocked">
    <h4 class="fw--700 my-5">
      <span class="fw--900">Gefeliciteerd!</span> Jouw persoonlijke, exclusieve
      aanbieding is ontgrendeld!
    </h4>
    <img :src="unlocked" />
  </div>
</template>

<script>
import unlocked from "@/assets/unlocked.png";
export default {
  name: "UnlockedView",
  data() {
    return {
      unlocked,
    };
  },
};
</script>

<style scoped lang="scss">
.unlocked {
  min-height: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
img {
  max-width: 275px;
}
h4 {
  max-width: 375px;
}
</style>
