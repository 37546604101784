<template>
  <div class="home">
    <HeaderMain />
    <SubHeader />
    <Reviews />
    <FooterSection />
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderMain from "@/components/landing-view/HeaderMain.vue";
import SubHeader from "@/components/landing-view/SubHeader.vue";
import Reviews from "@/components/landing-view/Reviews.vue";
import FooterSection from "@/components/FooterSection.vue";

export default {
  name: "Home",
  components: {
    HeaderMain,
    SubHeader,
    Reviews,
    FooterSection,
  },
  mounted() {
    let useScript = document.createElement("script");

    useScript.setAttribute(
      "src",
      "https://connect.facebook.net/en_US/fbevents.js"
    );
    useScript.setAttribute("async", true);

    window.fbq = function () {
      if (window.fbq.callMethod) {
        window.fbq.callMethod.apply(window.fbq, arguments);
      } else {
        window.fbq.queue.push(arguments);
      }
    };
    if (!window._fbq) {
      window._fbq = window.fbq;
    }

    window.fbq.push = window.fbq;
    window.fbq.loaded = true;
    window.fbq.version = "2.0";
    window.fbq.queue = [];
    fbq("init", "802121461641597");

    fbq("track", "PageView");

    document.head.appendChild(useScript);
  },
  created() {
    this.noscriptHtml =
      '<noscript><img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=802121461641597&ev=PageView&noscript=1" /></noscript>';
  },
};
</script>
