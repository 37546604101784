import { RepositoryFactory } from "../../repository/RepositoryFactory";

let leads = RepositoryFactory.get("leads");
const state = {
  submitLoader: false,
  responseId: null,
  isSubmitted: false,
  isFinalForm: false,
};

const getters = {
  getSubmitLoader: (state) => state.submitLoader,
  getProfileId: (state) => state.responseId,
  isSubmitted: (state) => state.isSubmitted,
  isFinalForm: (state) => state.isFinalForm,
};

const actions = {
  async postLead({ commit }, payload) {
    commit("setSubmitLoader", true);
    try {
      const response = await leads.postLead(payload);
      commit("setSubmitLoader", false);
      const responseId = response.data.profile.id;
      commit("setResponseId", responseId);
      commit("setIsSubmitted", true);
    } catch (error) {
      console.log(error.response);
      commit("setIsSubmitted", true);
      commit("setSubmitLoader", false);
    }
  },
  async setFinalForm({ commit }) {
    commit("setIsFinalForm", true);
  },
};

const mutations = {
  setSubmitLoader(state, payload) {
    state.submitLoader = payload;
  },
  setResponseId(state, payload) {
    state.responseId = payload;
  },
  setIsSubmitted(state, payload) {
    state.isSubmitted = payload;
  },
  setIsFinalForm(state, payload) {
    state.isFinalForm = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
