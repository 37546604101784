<template>
  <b-container class="pt-5 pb-3 text-center">
    <span
      v-b-modal.modal-actievoorwaarden
      class="text-decoration-underline cursor-pointer"
      >Actievoorwaarden</span
    >
    |
    <span
      v-b-modal.modal-privacy
      class="text-decoration-underline cursor-pointer"
      >Privacy policy</span
    >
    <!-- Modal -->
    <div>
      <b-modal
        id="modal-actievoorwaarden"
        scrollable
        title="Algemene voorwaarden"
        ok-only
        hide-header-close="false"
        ok-variant="success"
        size="lg"
      >
        <div>
          <p>
            Deze actie is een initiatief van Besparenkan (onderdeel van Motto
            Products B.V.). Besparenkan is o.a. een online marketing organisatie
            die voor derden online campagnes ontwikkelt t.b.v. het verzamelen
            van consumenteninformatie die actief interesse tonen in het
            product/aanbieding van de initiatiefnemer.
          </p>
          <p>
            Om deel te nemen aan de actie wordt toestemming verleend aan
            Besparenkan om de klant te benaderen met een voorstel voor een
            overstap naar een andere aanbieder. Hiermee bent u kosteloos
            klant/lid van de bespaarcommunity van Besparenkan. Besparenkan
            treedt op als bemiddelaar tussen de klant en aanbieder en ontvangt
            daarvoor een vergoeding van de aanbieder. Besparenkan heeft bij
            telefonisch energie advies gekozen voor een samenwerking met vijf
            geselecteerde leveranciers, te weten Budget Energie,
            Energiedirect.nl, Essent, Greenchoice en NLE waarvan wij speciaal
            door Besparenkan bedongen tarieven vergelijken met de tarieven die
            uw huidige leverancier momenteel op haar website communiceert. Dit
            zijn de laagste tarieven die Besparenkan u telefonisch kan
            aanbieden. Voor telefonisch internet, tv en bellen, sim-only advies
            heeft Besparenkan gekozen voor een samenwerking met Budget Thuis.
          </p>
          <p>
            Inzage en wijzigingen van uw gegevens<br />Om misbruik te voorkomen
            vragen wij u, bij een schriftelijk verzoek tot inzage, u adequaat te
            identificeren, door een kopie van een geldig legitimatiebewijs mee
            te sturen. Vergeet niet om op de kopie uw BSN én uw pasfoto af te
            schermen.
          </p>
          <p>
            Voor eventuele vragen, klachten en/of opmerkingen over deze campagne
            kunt u terecht bij:
          </p>
          <p>
            Telefonisch:<br />088 0550110<br />Maandag t/m vrijdag van 09.00 tot
            17.00 uur
          </p>
          <p>
            E-mail:<br />via het
            <a href="https://www.bespaarkiosk.nl/contact/">contactformulier</a>
            op onze website
          </p>
          <p>
            Post:<br />Besparenkan<br />t.a.v. afdeling Communicatie<br />Pastoor
            Vonckenstraat 21<br />6166 CV Geleen<br />KvK-nummer 14056449
          </p>
        </div>
      </b-modal>
      <!--  -->
      <b-modal
        id="modal-privacy"
        scrollable
        title="Privacy statement: Besparingexpert | Besparenkan 1 januari 2022"
        ok-only
        hide-header-close="false"
        ok-variant="success"
        size="lg"
      >
        <div>
          <p>
            <u>Wie zijn wij?</u><br />De website Besparingexpert i.s.m.
            Besparenkan is onderdeel van Motto Products B.V..
          </p>
          <p>
            <u>Wat is ons doel:</u><br />Ons doel is het aanbieden van een
            vergelijking tussen leveranciers van producten als energie,
            internet/TV en bellen, sim-only, zonnepanelen en verzekeringen,
            zodat u een voordeliger product kunt kiezen.
          </p>
          <p>
            <u>Welke gegevens verwerken wij van u:<br /></u><u></u>De door u
            verstrekte persoonsgegevens worden verwerkt om per productcategorie
            maximaal vier keer per jaar telefonisch, maximaal twee keer per
            maand per e-mail, maximaal één keer per maand per post en maximaal
            twee keer per maand per sms contact met u op te nemen om een
            vergelijking te maken tussen de leveranciers die wij aanbieden en uw
            leveranciers.&nbsp; Wij verwerken uw persoonsgegevens omdat u deze
            zelf aan ons verstrekt en ons daar met verzending van
            <span class="cursor-pointer">het formulier</span> toestemming voor
            geeft. Wij verwerken de persoonsgegevens die u zelf invult.
            Daarnaast registreren wij bij het verzenden van
            <span class="cursor-pointer">het formulier</span> nog andere
            gegevens. Onderstaand ziet u de gegevens die wij van u registreren:
          </p>
          <ul>
            <li>Geslacht (optioneel)</li>
            <li>Naam, postcode en huisnummer</li>
            <li>Contactgegevens zoals (mobiel) telefoonnummer</li>
            <li>E-mail adres (optioneel)</li>
            <li>Gezinssamenstelling en woningkenmerken</li>
            <li>Huidige energieleverancier</li>
            <li>Datum en tijd van aanmelding</li>
            <li>IP-adres en website van aanmelding</li>
          </ul>
          <p>
            Bespaarkiosk en Besparenkan zullen ervoor zorgen dat voor elke
            verwerking alleen die persoonsgegevens worden verwerkt die
            toereikend, terzake dienend en niet bovenmatig zijn. U kunt deze
            toestemming op ieder moment intrekken.
          </p>
          <p>
            <u>Bijzondere persoonsgegevens<br /></u>Wij willen geen gegevens
            verzamelen van bezoekers die jonger zijn dan 18 jaar, tenzij deze
            toestemming hebben van een ouder of voogd. We kunnen dit echter niet
            controleren. Heeft u aanleiding te veronderstellen dat wij gegevens
            hebben verzameld van een persoon jonger dan 18 jaar? Neemt u dan
            contact met ons op voor het verwijderen van die gegevens.
          </p>
          <p>
            Daarnaast gebruiken wij de gegevens voor de juiste verzending van de
            door u gekozen overeenkomst, en om u indien mogelijk op de hoogte te
            houden van andere interessante aanbiedingen. Indien u geen prijs
            stelt op deze extra informatie, kunt u dit kenbaar maken aan ons
            bedrijf: Besparenkan, t.a.v. databasemanagement, Pastoor
            Vonckenstraat 21, 6166 CV Geleen (KvK nummer 14056449)&nbsp; of via
            het
            <a href="https://www.bespaarkiosk.nl/contact/">contactformulier</a
            >&nbsp;op onze website of telefonisch op&nbsp;<strong
              >088 – 0550110</strong
            >.
          </p>
          <p>
            Alle gegevens die door onze website worden verzameld, zullen strikt
            vertrouwelijk bewaard worden. De informatie die u als klant aan ons
            geeft, zal met de grootst mogelijke zorg worden behandeld. Op ons
            online contactformulier verzoeken wij de gebruikers om ons van
            persoonlijke informatie te voorzien (bijvoorbeeld naam, postcode,
            huisnummer, telefoonnummer en e-mail adres). Deze gegevens worden
            door Besparenkan alleen gebruikt om contact met u op te kunnen
            nemen.
          </p>
          <p>
            <u>Hoe hebben wij uw gegevens beveiligd?<br /></u>Persoonsgegevens
            die wij opslaan (indien van toepassing) zijn goed beveiligd.
            Bescherming van uw privacy is belangrijk. Voor u én voor ons. Wij
            gaan uiterst zorgvuldig met de persoonsgegevens om en draagt,
            tezamen met eventuele verwerkers, zorg voor een passende
            organisatorische en technische beveiliging van haar bestanden waarin
            de persoonsgegevens worden opgeslagen. Op deze wijze stellen wij
            zeker dat deze gegevens alleen toegankelijk zijn voor personen die
            daar uit hoofde van hun functie toe bevoegd zijn en dat de gegevens
            alleen worden gebruikt voor de doeleinden waarvoor ze zijn
            verkregen. Verder hebben wij maatregelen genomen waarmee u onze
            websites veilig kunt bezoeken en gebruiken. Met die maatregelen
            voorkomen we misbruik van gegevens. Wij hebben onder meer de
            volgende maatregelen genomen:
          </p>
          <ul>
            <li>
              Deze website is beveiligd met een SSL certificaat. Daardoor wordt
              deze website opgeroepen onder HTTPS. HTTPS staat voor ‘HyperText
              Transfer Protocol Secure’. Dit is een uitbreiding op het HTTP
              protocol. Het HTTP protocol wordt gebruikt om een webbrowser
              (zoals Chrome, Internet Explorer of Firefox) te laten communiceren
              met een webserver waarop een website gehost wordt. De uitbreiding
              van het HTTPS protocol zorgt ervoor dat deze communicatie
              versleuteld wordt.
            </li>
            <li>
              Uw gegevens worden opgeslagen in een beveiligde database met
              authenticatie.
            </li>
            <li>
              Deze database is alleen toegankelijk voor bevoegde personen door
              middel van wachtwoorden.
            </li>
          </ul>
          <p>
            <u>Hoe lang bewaren wij uw gegevens:</u><br />Wij bewaren
            persoonsgegevens niet langer dan noodzakelijk.
          </p>
          <p>
            Het verwijderen van uw gegevens betekent niet dat uw gegevens ook
            altijd vernietigd worden. De gegevens worden in ieder geval buiten
            bereik van de actieve administratie gebracht. Uw gegevens zullen in
            een archief bewaard worden voor historische, statistische of
            wetenschappelijke doeleinden. Niet noodzakelijke gegevens zullen
            verwijderd worden. Gegevens voor de administratie zullen wij langer
            bewaren.
          </p>
          <p>
            <u>Recht van Bezwaar:<br /></u>Indien u niet meer telefonisch door
            ons benaderd wilt worden voor een energieaanbod kunt u dat aangeven
            via&nbsp;<a href="mailto:info@besparenkan.nl">info@besparenkan.nl</a
            >&nbsp;of bellen met&nbsp;<strong>088 – 0550110&nbsp;</strong>of via
            het&nbsp;<a href="https://besparenkan.nl/afmelden">formulier</a> op
            onze website. Vermeldt duidelijk uw naam en telefoonnummer in uw
            bericht en dan melden wij u aan voor het Recht van Bezwaar.
          </p>
          <p>
            <u>Rechten van betrokkenen:</u><br />Onder de AVG heeft iedereen een
            aantal rechten. Wij doen ons uiterste best om hier zo goed mogelijk
            aan te voldoen. Om van uw rechten gebruik te maken kunt u contact
            met ons opnemen&nbsp;via het&nbsp;<a
              href="https://www.bespaarkiosk.nl/contact/"
              >contactformulier</a
            >&nbsp;op onze website&nbsp;of telefonisch op&nbsp;<strong
              >088 – 0550110</strong
            >.
          </p>
          <ul>
            <li>
              <strong>Informatie en inzage: </strong>Uiteraard kunt u inzien
              welke persoonsgegevens we van u verwerken. Voor de voorwaarden van
              een dergelijk verzoek; meer informatie hierover kunt u vinden
              op&nbsp;<a
                href="https://autoriteitpersoonsgegevens.nl/nl/zelf-doen/privacyrechten/recht-op-inzage"
                >de recht van inzage pagina van mijnprivacy.nl</a
              >
              (een website van de Autoriteit Persoonsgegevens).
            </li>
            <li>
              <strong>Rectificatie: </strong>Heeft u het idee dat we verkeerde
              gegevens van u hebben? Laat het ons dan weten, dan zorgen wij
              ervoor dat het aangepast wordt.
            </li>
            <li>
              <strong>Vergetelheid: </strong>U kunt de persoonsgegevens die wij
              van u hebben, laten verwijderen. Het kan zijn dat we voor andere
              doeleinden (administratie of ontdubbelen bijvoorbeeld) die
              gegevens dan nog wel moeten verwerken.
            </li>
            <li>
              <strong>Beperking: </strong>Denkt u dat wij uw persoonsgegevens
              onrechtmatig of onjuist verwerken, dan kunt u die verwerking ook
              laten beperken.
            </li>
            <li>
              <strong>Bezwaar (verzet): </strong>Voor de verwerking van bepaalde
              persoonsgegevens kunt u bezwaar indienen.
            </li>
            <li>
              <strong>Overdraagbaarheid: </strong>Dit is een nieuw recht onder
              de AVG om uw persoonsgegevens over te (laten) dragen.
            </li>
            <li>
              <strong>Toestemming intrekken: </strong>Bijvoorbeeld voor het
              ontvangen van e-mails.
            </li>
          </ul>
          <p>
            Indien u het niet eens bent met de afhandeling van uw verzoek heeft
            u de mogelijkheid een klacht in te dienen bij de Autoriteit
            Persoonsgegevens (<a href="https://autoriteitpersoonsgegevens.nl/"
              >https://autoriteitpersoonsgegevens.nl</a
            >).
          </p>
          <p>
            <u>Hyperlinks:</u><br />Deze website(s) kan links naar andere
            websites bevatten. Wij zijn niet verantwoordelijk voor het
            Privacybeleid of de inhoud van deze andere websites. Wij raden u aan
            kennis te nemen van de privacy verklaringen op deze websites.
          </p>
          <p>
            <u>Cookies:</u><br />Cookies zijn kleine tekstbestanden die door een
            pagina van de website op de pc, tablet of mobiele telefoon van de
            bezoeker worden geplaatst. In zo’n cookie wordt informatie
            opgeslagen zoals bepaalde voorkeuren van de bezoeker en/of
            gebruiksgegevens. Daardoor kan de website u, als bezoeker,
            bijvoorbeeld bij een volgend bezoek nog beter van dienst zijn. Wij
            gebruiken bijvoorbeeld ook cookies om trends te analyseren, deze
            website te beheren, het gebruik te volgen (zoals aantal bezoekers,
            de stille en drukke periodes) en om algemene demografische
            informatie te verzamelen. Op de website kunt u uw toestemming geven
            voor het plaatsen van de cookies.
          </p>
          <p data-gtm-vis-has-fired-569483_277="1">
            Noodzakelijk en statistiek:<b><br /></b>Dit soort cookies maakt het
            mogelijk dat een website naar behoren functioneert.&nbsp; Ook
            verkrijgen we door dit soort cookies (anonieme) informatie over de
            kwaliteit en effectiviteit van onze website. Zo gebruiken we een
            cookie om bijvoorbeeld aan verschillende website-bezoekers
            verschillende varianten van pagina’s te tonen. Zo kunnen we testen
            welke van die varianten de beste gebruikersbeleving oplevert.
          </p>
          <p data-gtm-vis-has-fired-569483_277="1">
            Optimale cookies:<b><br /></b>Dit soort cookies gebruiken we alleen
            als u daar eerst toestemming voor hebt gegeven. Voorbeelden van dit
            soort cookies zijn van advertentie netwerken zoals Google Ads, Bing,
            Facebook, Taboola, Outbrain, enz.
          </p>
          <p>
            De bezoeker kan zelf bepalen hoe er met cookies omgegaan moet worden
            (keuze tussen noodzakelijke cookies en optimale cookies). Hij kan
            ook zijn browser zo instellen dat die het gebruik van cookies
            toestaat, niet toestaat of gedeeltelijk toestaat. In dit laatste
            geval kan worden ingesteld welke websites cookies mogen plaatsen.
            Bij alle overige websites wordt het dan verboden. Deze mogelijkheid
            wordt door de meest gebruikte moderne browsers geboden. U kunt uw
            cookies verwijderen via uw browser.
          </p>
          <p>
            Sociale netwerken:<br />Op onze website zijn buttons opgenomen om
            webpagina’s te kunnen promoten of delen op sociale netwerken als
            Facebook en Twitter. Deze buttons werken door middel van stukjes
            code die van Facebook en Twitter zelf afkomstig zijn. Door middel
            van deze code worden cookies geplaatst. Wij hebben daar geen invloed
            op. Lees de privacyverklaring van&nbsp;<a
              href="https://www.facebook.com/privacy/explanation"
              >Facebook</a
            >&nbsp;en&nbsp;<a href="https://twitter.com/en/privacy">Twitter</a
            >&nbsp;(welke regelmatig kunnen wijzigen) om te lezen wat zij met uw
            (persoons)gegevens doen die zij via deze cookies verwerken.
          </p>
          <p>
            <u>Disclaimer</u><br />Alle foto’s, graphics en teksten zijn
            eigendom van Besparenkan (behalve de logo’s van de leveranciers die
            op de website toonbaar zijn). Deze mogen niet zonder voorafgaande
            schriftelijke toestemming van Besparenkan worden verveelvoudigd,
            gekopieerd, gepubliceerd, opgeslagen, aangepast of gebruikt worden
            in welke vorm dan ook en voor welk doel dan ook, noch offline, noch
            online. Inbreuk is bovendien een strafbaar feit. Onverminderd de
            civielrechtelijke bevoegdheden om inbreuk tegen te gaan en/of om
            schadevergoeding te vorderen, behoudt Besparenkan zich het recht
            voor om aangifte te doen.
          </p>
          <p>
            <u>Vragen?<br /></u>Hoewel Besparenkan uw persoonsgegevens met
            uiterste zorgvuldigheid behandelt, zijn wij niet aansprakelijk voor
            eventuele fouten en/of schade die daarvan direct of indirect het
            gevolg zou kunnen zijn. Voor vragen of opmerkingen over ons
            Privacybeleid, kunt u contact met ons opnemen.
          </p>
          <p>
            Wanneer u vragen heeft die niet in deze privacyverklaring zijn
            beantwoord of wanneer u suggesties of opmerkingen heeft over de
            inhoud daarvan, dan kunt u dit kenbaar maken aan ons bedrijf:
            Besparenkan, t.a.v. databasemanagement, Pastoor Vonckenstraat 21,
            6166 CV Geleen (KvK nummer 14056449) of via het
            <a href="https://www.bespaarkiosk.nl/contact/">contactformulier</a
            >&nbsp;op onze website of telefonisch op 088 – 0550110.
          </p>
          <p>
            Besparenkan behoudt zich te allen tijde en met en zonder
            kennisgeving het recht voor om wijzigingen aan te brengen in deze
            privacyverklaring, bijvoorbeeld vanwege nieuwe ontwikkelingen,
            bedrijfsactiviteiten, online diensten of als er iets verandert in de
            wet of rechtspraak. Wij raden u daarom aan om van tijd tot tijd
            opnieuw deze privacyverklaring te raadplegen zodat u bekend bent met
            eventuele wijzigingen in deze privacyverklaring.
          </p>
        </div>
      </b-modal>
    </div>
  </b-container>
</template>
<script>
export default {
  name: "FooterSection",
};
</script>
<style lang="scss" scoped></style>
