<template>
  <b-container class="py-5 text-center">
    <h2 class="fw--700 m-0">Reviews</h2>
    <p>De ervaringen van andere klanten van BesparenKan</p>
    <b-row class="reviews__card">
      <b-col sm="12" md="6" class="text-center reviews__card--section">
        <img :src="reviewsStars" class="mb-3" />
        <p>
          Na nare ervaringen met andere energie leveranciers kwam ik in contact
          met BesparenKan die mij alles geduldig hebben uitgelegd en de gemaakte
          afspraken over o.a terugbellen netjes nakwamen. Als ik vragen had werd
          daar snel en correct op gereageerd. Een telefoontje of een mailtje
          naar Besparenkan.nl was genoeg.Tot nu toe ben ik erg tevreden over het
          contract.
        </p>
        <img :src="divider" class="my-3" />
        <p class="fw--700">Miriam Vermeulen</p>
      </b-col>
      <b-col sm="12" md="6" class="text-center reviews__card--section">
        <img :src="reviewsStars" class="mb-3" />
        <p>
          Ik wil BesparenKan bedanken voor de besparing die mij gerealiseerd is
          mijn contract liep af en dit resulteerde mij tot een flinke verhoging,
          door deze besparing ben ik er 88,20 per maand op vooruit gegaan enorm
          bedankt
        </p>
        <div>
          <img :src="divider" class="my-3" />
          <p class="fw--700">Aad van Doveren</p>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import reviewsStars from "@/assets/reviews-stars.png";
import divider from "@/assets/review-divider.png";
import reviewsDivider from "@/assets/review-divider1.png";
export default {
  name: "Reviews",
  data() {
    return {
      reviewsStars,
      divider,
    };
  },
};
</script>
<style lang="scss" scoped>
.reviews {
  &__card {
    background-color: #1f51b9;
    color: #ffffff;
    border-radius: 20px;
    max-width: 80%;
    margin: auto;
    margin-top: 3rem;
    @media (max-width: 991.98px) {
      max-width: unset;
    }

    &--section {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 3rem;
    }
  }
}
p {
  margin: 0;
}
</style>
