<template>
  <div class="FormCardMain">
    <div v-if="showQuestions">
      <CardHeader v-if="!showLoaderScreen" />
      <QuestionLoader
        v-else
        :questionNo="questionNo"
        :typeWoning="typeWoning"
        :gezinssamenstelling="gezinssamenstelling"
        :question2answer="question2answer"
      />
      <QuestionsFlow
        v-show="!showLoaderScreen"
        :questionNo="questionNo"
        @submit-form="handleFormSubmit"
        @set-question="handleQuestionNumber"
        @set-label="setLabel"
        @set-have-panels="setHavePanels"
      />
      <LockedQuestionBar
        v-if="!showLoaderScreen"
        :questionNo="questionNo"
        @update-question="handleUpdateQuestion"
      />
    </div>
    <UnlockedView v-else-if="questionNo === 6 && !isSubmitted" />
    <FinalForm
      v-else-if="questionNo === 7 && !isSubmitted"
      :flowAnswers="flowAnswers"
    />
    <Bedankt v-else />
  </div>
</template>

<script>
import CardHeader from "./CardHeader.vue";
import QuestionLoader from "./QuestionLoader.vue";
import UnlockedView from "./UnlockedView.vue";
import QuestionsFlow from "./QuestionsFlow.vue";
import LockedQuestionBar from "./LockedQuestionBar.vue";
import FinalForm from "./FinalForm.vue";
import Bedankt from "./Bedankt.vue";

export default {
  name: "FormCardMain",
  components: {
    CardHeader,
    QuestionLoader,
    UnlockedView,
    LockedQuestionBar,
    QuestionsFlow,
    FinalForm,
    Bedankt,
  },
  data() {
    return {
      questionNo: 1,
      showLoaderScreen: false,
      typeWoning: "",
      gezinssamenstelling: "",
      question2answer: "",
      flowAnswers: null,
    };
  },

  methods: {
    handleUpdateQuestion(selectedQuestion) {
      this.questionNo = selectedQuestion;
    },
    handleQuestionNumber() {
      this.questionNo--;
    },
    handleFormSubmit(formData) {
      this.showLoaderScreen = true;
      this.flowAnswers = formData;
      setTimeout(() => {
        this.questionNo++;
        this.showLoaderScreen = false;
      }, 3500);
    },
    setLabel(label) {
      if (this.questionNo === 4) {
        this.typeWoning = label;
      } else if (this.questionNo === 5) {
        this.gezinssamenstelling = label;
      }
    },
    setHavePanels(label) {
      this.question2answer = label;
    },
  },
  watch: {
    questionNo(newValue) {
      if (newValue === 6) {
        setTimeout(() => {
          this.setFinalForm();
          this.questionNo++;
        }, 2000);
      }
    },
  },
  computed: {
    showQuestions() {
      return this.questionNo <= 5 && !this.isSubmitted;
    },
  },
};
</script>

<style scoped lang="scss">
.FormCardMain {
  min-height: 400px;
  height: 100%;
}
</style>
