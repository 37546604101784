<template>
  <div class="questions__loader">
    <b-spinner style="width: 3rem; height: 3rem" variant="warning" />
    <h5 class="fw--400 my-3">
      {{ questions[questionNo - 1].title }}
    </h5>
    <div class="questions__loader--bottom">
      <h5
        v-if="questionNo == 1 && isAddress"
        class="my-1 mb-2 text-warning-site fw--600"
      >
        {{ showAddress }}
      </h5>
      <h5 v-show="showSubTitle" class="fw--700 text-success-site mb-4">
        {{ questions[questionNo - 1].subTitle }}
      </h5>
      <img v-show="showImg" :src="lockImg" width="200" />
    </div>
  </div>
</template>

<script>
import lockImg from "@/assets/loader-lock.png";
export default {
  name: "QuestionLoader",
  props: ["questionNo", "typeWoning", "gezinssamenstelling", "question2answer"],
  data() {
    return {
      lockImg,
      questions: [
        {
          title: "Adres wordt gecontroleerd...",
          subTitle: "BesparenKan is actief in jouw regio",
        },
        {
          title: "Opties zonnepanelen wordt gecontroleerd...",
          subTitle: this.question2answer,
        },
        {
          title: "Energieleverancier wordt gecontroleerd...",
          subTitle:
            "BesparenKan heeft mogelijk een scherper aanbod beschikbaar",
        },
        {
          title: "Type woning wordt gecontroleerd...",
          subTitle: `BesparenKan heeft aanbod voor ${this.typeWoning}`,
        },
        {
          title: "Gezinssamenstelling wordt gecontroleerd...",
          subTitle: `BesparenKan heeft aanbod voor ${this.gezinssamenstelling}`,
        },
      ],
      showSubTitle: false,
      showImg: false,
      isAddress: false,
    };
  },
  created() {
    setTimeout(() => {
      this.showImg = true;
    }, 2300);
    setTimeout(() => {
      this.showSubTitle = true;
    }, 1300);
    setTimeout(() => {
      this.isAddress = true;
    }, 1000);
  },
  computed: {
    showAddress() {
      let address = this.getFullAddress;
      if (address) {
        return `${address.streetNumberAndPremise}, ${address.street}, ${address.settlement}`;
      }
    },
  },
};
</script>

<style scoped lang="scss">
img {
  max-width: 100%;
}
.questions__loader {
  min-height: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &--bottom {
    height: 108px;
  }
}
</style>
