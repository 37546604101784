import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters([
      "getAddressLoader",
      "getFullAddress",
      "getSubmitLoader",
      "getProfileId",
      "isSubmitted",
      "isFinalForm",
    ]),
  },
  methods: {
    ...mapActions(["getAddress", "postLead", "setFinalForm"]),
  },
};
