import axios from "axios";

const baseDomain = "https://api.pro6pp.nl/v2/suggest";
const baseURL = `${baseDomain}/nl/streetNumberAndPremise`;
let axiosObj;
axiosObj = axios.create({
  baseURL,
});

const apiKey = "P6JTU52clKYjZca8";

export default {
  getAddress(payload) {
    return axiosObj.get(
      `?authKey=${apiKey}&postalCode=${payload.zip}&streetNumberAndPremise=${payload.house_number}`
    );
  },
};
