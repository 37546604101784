<template>
  <div class="questions m-4 mt-0">
    <div v-if="questionNo > 1" class="questions__back--btn">
      <img :src="backIcon" class="back-btn" @click="$emit('set-question')" />
    </div>
    <b-form @submit.prevent="onSubmit" class="questions__row">
      <div v-if="questionNo == 1" class="questions__1">
        <h3 class="fw--700 py-3">{{ getQuestionTitle(questionNo) }}</h3>
        <b-row class="mb-3">
          <b-col>
            <b-form-input
              id="postcode"
              placeholder="Postcode"
              v-model="formData.zip"
              :state="zipValidation"
              maxlength="6"
              required
            ></b-form-input>
          </b-col>
          <b-col>
            <b-form-input
              id="huisnummer"
              placeholder="Huisnummer"
              v-model="formData.house_number"
              :state="houseValidation"
              required
            ></b-form-input>
          </b-col>
        </b-row>
        <small v-if="showAddressError" class="my-3 text-danger">
          adres niet gevonden voor u opgegeven postcode en huisnummer
        </small>
        <b-button
          type="submit"
          pill
          variant="success"
          class="my-3 px-4 submit-btn"
        >
          <b-spinner
            small
            v-if="getAddressLoader"
            class="text-center mx-5 my-1"
          />
          <span v-else> Ga verder &nbsp;&nbsp;<img :src="arrowIcon" /> </span>
        </b-button>
      </div>

      <div v-if="questionNo == 2" class="questions__2">
        <h3 class="fw--700 py-3">{{ getQuestionTitle(questionNo) }}</h3>
        <div class="d-flex">
          <div class="text-start mx-auto">
            <b-form-radio
              v-model="formData.answers[1]"
              name="question2"
              value="3976"
              @change="onSubmit"
            >
              Ja, ik heb zonnepanelen
            </b-form-radio>
            <b-form-radio
              v-model="formData.answers[1]"
              name="question2"
              value="3979"
              @change="onSubmit"
            >
              Nee, ik heb geen zonnepanelen
            </b-form-radio>
          </div>
        </div>
      </div>

      <div v-if="questionNo == 3" class="questions__3">
        <h3 class="fw--700 py-3">{{ getQuestionTitle(questionNo) }}</h3>
        <b-form-select
          v-model="formData.answers[2]"
          placeholder="Maak een keuze..."
          @change="onSubmit"
        >
          <option value="" disabled>Maak een keuze...</option>
          <option
            v-for="option in options"
            :key="option.value"
            :value="option.value"
          >
            {{ option.label }}
          </option>
        </b-form-select>
      </div>

      <div v-if="questionNo === 4 || questionNo === 5">
        <h3 class="fw--700 py-3">{{ getQuestionTitle(questionNo) }}</h3>
        <div class="d-flex flex-wrap checkbox-item-wraper">
          <div
            v-for="item in getQuestionOptions(questionNo)"
            :key="item.label"
            class="checkbox-item mr-2 mt-1"
            @click="onSubmit(item.value), setLabel(item.text)"
          >
            <div
              class="checkbox-item__img cursor-pointer d-flex justify-content-center align-items-center p-2 p-lg-4 position-relative"
            >
              <img :src="item.image" class="img-fluid" />
            </div>
            <p
              class="checkbox-item__label mb-0 mt-1 text-center cursor-pointer"
            >
              {{ item.label }}
            </p>
          </div>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import arrowIcon from "@/assets/btn-arrow.png";
import vrijstaandImg from "@/assets/vrijstaand.png";
import onderImg from "@/assets/2-onder-1-kap.png";
import hoekwoningImg from "@/assets/hoekwoning.png";
import appartementImg from "@/assets/appartement.png";
import singleImg from "@/assets/single.png";
import grootImg from "@/assets/groot-gezin.png";
import gezinImg from "@/assets/gezin.png";
import kleinImg from "@/assets/klein-gezin.png";
import duoImg from "@/assets/duo.png";
import backIcon from "@/assets/back-arrow.png";

export default {
  name: "QuestionsFlow",
  props: ["questionNo"],
  data() {
    return {
      arrowIcon,
      backIcon,
      isValidZipAndHouseNumber: false,
      showAddressError: false,
      formData: {
        zip: "",
        house_number: "",
        street: "",
        city: "",
        answers: ["3913", "", "", "", ""],
      },
      checkboxItems: [
        {
          label: "Appartement",
          image: appartementImg,
          value: "3931",
          text: "appartementen",
        },
        {
          label: "Tussenwoning",
          image: hoekwoningImg,
          value: "3934",
          text: "tussenwoningen",
        },
        {
          label: "Hoekwoning",
          image: hoekwoningImg,
          value: "3937",
          text: "hoekwoningen",
        },
        {
          label: "2-onder 1 kap",
          image: onderImg,
          value: "3940",
          text: "2-onder 1 kappers",
        },
        {
          label: "Vrijstaand",
          image: vrijstaandImg,
          value: "3943",
          text: "vrijstaande woningen",
        },
      ],
      personCheckboxItems: [
        {
          label: "Alleenstaand",
          image: singleImg,
          value: "3916",
          text: "alleenstaande",
        },
        {
          label: "Samenwonend",
          image: duoImg,
          value: "3919",
          text: "samenwonende",
        },
        {
          label: "3 personen",
          image: kleinImg,
          value: "3922",
          text: "3 personen",
        },
        {
          label: "4 personen",
          image: gezinImg,
          value: "3925",
          text: "4 personen",
        },
        {
          label: "Groot gezin",
          image: grootImg,
          value: "3928",
          text: "grote gezinnen",
        },
      ],
      options: [
        { label: "Anders", value: "3973" },
        { label: "Budget Energie", value: "3955" },
        { label: "Delta", value: "3970" },
        { label: "Energiedirect", value: "3964" },
        { label: "Eneco", value: "3949" },
        { label: "Essent", value: "3946" },
        { label: "Greenchoice", value: "3958" },
        { label: "OXXIO", value: "3967" },
        { label: "Vandebron", value: "3961" },
        { label: "Vattenfall", value: "3952" },
      ],
    };
  },
  methods: {
    async onSubmit(selectedValue) {
      if (this.questionNo === 2) {
        this.setQuestion2();
      }
      if (this.questionNo === 4) {
        this.formData.answers[3] = selectedValue;
      }
      if (this.questionNo === 5) {
        this.formData.answers[4] = selectedValue;
      }
      if (this.questionNo == 1 && this.isValidZipAndHouseNumber) {
        let payload = {
          house_number: this.formData.house_number,
          zip: this.formData.zip,
        };
        let resp = await this.getAddress(payload);
        if (resp.addressExists) {
          this.formData.street = resp.addressExists.street;
          this.formData.city = resp.addressExists.settlement;
          this.$emit("submit-form", this.formData);
        } else {
          this.showAddressError = true;
        }
      }
      if (this.questionNo > 1) {
        this.$emit("submit-form", this.formData);
      }
    },
    getQuestionTitle(questionNo) {
      const titles = [
        "Controleer of wij actief zijn in jouw regio",
        "Heeft u zonnepanelen?",
        "Huidige energieleverancier",
        "Welk type woning heeft u?",
        "Wat is je gezinssamenstelling?",
      ];
      return titles[questionNo - 1];
    },
    getQuestionOptions(questionNo) {
      if (questionNo == 4) return this.checkboxItems;
      if (questionNo == 5) return this.personCheckboxItems;
    },
    setLabel(label) {
      this.$emit("set-label", label);
    },
    setQuestion2() {
      let selectedValue = this.formData.answers[1];
      let label = "";
      if (selectedValue === "3976") {
        label = "BesparenKan heeft aanbod voor huishoudens met zonnepanelen";
      } else {
        label = "BesparenKan heeft aanbod voor huishoudens zonder zonnepanelen";
      }
      this.$emit("set-have-panels", label);
    },
  },
  watch: {
    showAddressError(newValue, oldValue) {
      if (newValue) {
        setTimeout(() => {
          this.showAddressError = false;
        }, 2500);
      }
    },
  },
  computed: {
    zipValidation() {
      if (this.formData.zip == "") {
        this.isValidZipAndHouseNumber = false;
        return null;
      }
      if (/^([0-9]{4}[a-zA-Z]{2})$/.test(this.formData.zip)) {
        this.isValidZipAndHouseNumber = true;
        return true;
      } else {
        this.isValidZipAndHouseNumber = false;
        return false;
      }
    },
    houseValidation() {
      if (this.formData.house_number == "") {
        this.isValidZipAndHouseNumber = false;
        return null;
      }
      if (/(\w?[0-9]+[a-zA-Z0-9\- ]*)/.test(this.formData.house_number)) {
        this.isValidZipAndHouseNumber = true;
        return true;
      } else {
        this.isValidZipAndHouseNumber = false;
        return false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.questions {
  &__back--btn {
    text-align: start;
    & img {
      cursor: pointer;
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      width: 25px;
    }
  }
  &__row {
    max-width: 80%;
    margin: auto;
    @media (max-width: 991.98px) {
      max-width: 100%;
    }
  }
  &__2 {
    label .custom-control-label {
      margin-left: 15px !important;
      font-weight: 600 !important;
    }
  }

  .checkbox-item-wraper {
    justify-content: space-evenly;
  }
  .checkbox-item {
    width: 18%;
    @media (max-width: 767.99px) {
      width: 45% !important;
    }
  }
  .checkbox-item__img {
    background-color: #ebe9e9;
    border-radius: 10px;
    height: 72px;
  }
  .checkbox-item__label {
    color: #000;
    font-size: 13px;
    font-weight: 400;
    line-height: 27px;
  }
}
</style>
